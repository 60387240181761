import { Routes, Route, Outlet, Link } from "react-router-dom";

import logo from './logo.svg';
import './App.css';
import './sass/mystyles.scss'
import '@fortawesome/fontawesome-svg-core'
import '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/js/all'

import { Home } from "./pages/Home"
import { Privacy } from "./pages/Privacy";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}>
        </Route>
        <Route path="/privacy" element={<Privacy />}></Route>
      </Routes>
    </>
  );
}

export default App;
