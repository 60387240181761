import { Outlet } from "react-router";

export const Home = () => {
    return (
        <>
            <section className="hero main_hero is-fullheight is-hidden-touch">
                <div className="hero-head">
                    <div className="first_nav">
                        <div className="container">
                            <nav className="navbar">
                                <div className="navbar-brand">
                                    <a
                                        className="navbar-item is-hidden-desktop"
                                        href="tel:+393929308548"
                                    >
                                        <span className="icon" style={{ color: '#333' }}>
                                            <i className="fa fa-lg fa-phone"></i>
                                        </span>
                                    </a>
                                    <a
                                        className="navbar-item is-hidden-desktop"
                                        href="https://m.facebook.com/#!/HomeTrustimmobiliare/"
                                        target="_blank"
                                    >
                                        <span className="icon">
                                            <i className="fab fa-lg fa-facebook-f"></i>
                                        </span>
                                    </a>
                                    {/* <!-- Mobile Menu -->
                                <!-- <div className="navbar-burger burger is-hidden-mobile is-hidden-tablet" data-target="navMenuBlogHero">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div> --> */}
                                </div>
                                {/* <!-- Menu --> */}
                                <div id="navMenuBlogHero" className="navbar-menu">
                                    {/* <!-- Left Nav --> */}
                                    <div className="navbar-start">
                                        <a className="navbar-item is-active">Home</a>
                                        <a className="navbar-item" href="#mission">Mission</a>
                                        <a className="navbar-item" href="#about-us">Su di noi</a>
                                        <a className="navbar-item" href="#services">Servizi</a>
                                        <a className="navbar-item" href="#contact-us">Contatti</a>
                                    </div>
                                    {/* <!-- ./Left Nav -->
                                <!-- Right Nav --> */}
                                    <div className="navbar-end">
                                        <div className="navbar-item">
                                            <div className="field is-grouped">
                                                <p className="control">
                                                    <a
                                                        className="button is-normal is-facebook"
                                                        href="https://m.facebook.com/#!/HomeTrustimmobiliare/"
                                                    >
                                                        <span className="icon">
                                                            <i className="fab fa-facebook-f"></i>
                                                        </span>
                                                        <span>Facebook</span>
                                                    </a>
                                                </p>
                                                <p className="control">
                                                    <a
                                                        className="button is-normal is-success"
                                                        href="tel:+393929308548"
                                                    >
                                                        <span className="icon">
                                                            <i className="fa fa-phone"></i>
                                                        </span>
                                                        <span>Call Us</span>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="hero-body">
                </div>
            </section>



            <section className="hero main_hero is-halfheight is-hidden-desktop"></section>

            <section id="mission">
                <div className="container">
                    <div
                        className="columns is-one-third is-flex-wrap is-justify-content-center mt-6"
                    >
                        <div className="column is-three-fifths px-4 mr-auto ml-auto">

                            <h3 className="is-size-1 is-size-4-touch mb-2 has-text-weight-semibold">
                                La tua fiducia è molto importante
                            </h3>
                            <p className="is-size-4 is-size-6-touch mt-4 mb-4 has-text-justified">
                                Home Trust crede fermamente che la figura dell’agente immobiliare
                                debba essere quella di una persona di fiducia, alla quale si
                                sceglie di affidare la gestione dei propri beni immobili e dei
                                propri investimenti, che rappresentano una parte fondamentale
                                della vita di maggior parte di noi; essendone consapevoli
                                ripaghiamo questa fiducia lavorando sodo, con la massima
                                professionalità e trasparenza, per il cliente e con il cliente, in
                                base alle sue indicazioni e alle sue esigenze, per fornire un
                                servizio quanto più su misura possibile.
                            </p>

                        </div>
                        <div className="column">
                            <div
                                className="box px-4 mr-auto ml-auto has-background-light-goldenrod-yellow"
                            >

                                <div className=""></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about-us">
                <div className="mt-6 ml-0 mr-0 is-flex absolute">
                    <svg
                        className="absolute"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon className="fill-morning-blue" points="0 250 2560 250 2560 0" />
                    </svg>
                </div>
                <div className="has-background-morning-blue">
                    <div className="container">
                        <div className="columns mt-0 mb-0">
                            <div className="column is-two-fifths ml-auto mr-auto px-4">

                            </div>
                            <div className="column ml-auto mr-auto px-4">
                                <div className="">
                                    <div
                                        className="has-text-light-goldenrod-yellow p-3 has-text-centered inline-flex items-center justify-center mb-6 shadow-lg rounded-full"
                                    >
                                        <i
                                            className="fas fa-bullhorn big-icon has-text-light-goldenrod-yellow has-background-white is-circular"
                                        ></i>
                                    </div>
                                    <h3
                                        className="is-size-1 is-size-4-touch has-text-weight-semibold has-text-xinetic"
                                    >
                                        Di più sull'azienda
                                    </h3>
                                    <p
                                        className="is-size-4 is-size-6-touch has-text-xinetic has-text-justified"
                                    >
                                        Siamo un team giovane e qualificato, con anni di esperienza
                                        nel settore immobiliare e nel mercato locale. La mission
                                        dell’azienda è quella di rendere ai nostri clienti, che siano
                                        acquirenti o venditori, un servizio professionale e
                                        trasparente, poiché per noi il maggior compenso è un cliente
                                        soddisfatto.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-auto mt-0 ml-0 mr-0 is-full absolute overflow-hidden">
                    <svg
                        className="absolute m-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-morning-blue fill-current"
                            points="0 0 0 250 2560 0"
                        />
                    </svg>
                </div>
            </section>

            <section id="services">
                <div className="mt-6 ml-0 mr-0 is-flex absolute">
                    <svg
                        className="absolute mb-0"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-turquoise-green fill-current"
                            points="0 0 0 250 2560 250"
                        />
                    </svg>
                </div>
                <div className="has-background-turquoise-green">
                    <div className="container mx-auto px-4">
                        <div className="columns mt-0 mb-0">
                            <div className="column ml-auto mr-auto px-4">
                                <div className="">
                                    <h3
                                        className="is-size-1 is-size-4-touch has-text-weight-semibold has-text-xinetic"
                                    >
                                        Cooperazione fra professionisti
                                    </h3>
                                    <p
                                        className="is-size-4 is-size-6-touch has-text-xinetic has-text-justified"
                                    >
                                        Rivolgersi a noi equivale ad appoggiarsi a tutte le agenzie
                                        cooperative sul territorio, poiché consideriamo la
                                        collaborazione tra agenzie un punto di forza, per noi e per il
                                        mercato, rendendo un servizio più rapido ed efficiente al
                                        cliente.
                                    </p>
                                </div>
                            </div>
                            <div className="column is-two-fifths ml-auto mr-auto px-4">
                                <div
                                    className="p-3 has-text-centered inline-flex items-center justify-center mb-6"
                                >
                                    <i
                                        className="fas fa-hands-helping big-icon has-text-light-goldenrod-yellow has-background-white is-circular"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-auto mt-0 ml-0 mr-0 is-flex">
                    <svg
                        className="absolute m-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-turquoise-green fill-current"
                            points="0 0 2560 0 2560 250"
                        />
                    </svg>
                </div>
            </section>

            <section>
                <div className="mt-6 ml-0 mr-0 is-flex absolute">
                    <svg
                        className="absolute"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon className="fill-morning-blue" points="0 250 2560 250 2560 0" />
                    </svg>
                </div>
                <div className="has-background-morning-blue">
                    <div className="container">
                        <div className="columns mt-0 mb-0">
                            <div className="column is-two-fifths ml-auto mr-auto px-4">
                                <div
                                    className="p-3 has-text-centered inline-flex items-center justify-center mb-6 shadow-lg rounded-full"
                                >
                                    <i
                                        className="fas fa-eye big-icon has-text-light-goldenrod-yellow has-background-white is-circular"
                                    ></i>
                                </div>
                            </div>
                            <div className="column ml-auto mr-auto px-4">
                                <div className="">
                                    <h3
                                        className="is-size-1 is-size-4-touch has-text-weight-semibold has-text-xinetic"
                                    >
                                        Solo clienti referenziati
                                    </h3>
                                    <p className="is-size-4 is-size-6-touch has-text-xinetic">
                                        La verifica del cliente, che sia acquirente o venditore è alla
                                        base di tutto, per questo in fase di offerta richiediamo al
                                        cliente la documentazione necessaria per una corretta verifica
                                        della sua identità e/o solvibilità, oltre ad effettuare
                                        controlli su banche dati apposite.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-auto mt-0 ml-0 mr-0 is-full absolute overflow-hidden">
                    <svg
                        className="absolute m-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-morning-blue fill-current"
                            points="0 0 0 250 2560 0"
                        />
                    </svg>
                </div>
            </section>

            <section>
                <div className="mt-6 ml-0 mr-0 is-flex absolute">
                    <svg
                        className="absolute mb-0"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-turquoise-green fill-current"
                            points="0 0 0 250 2560 250"
                        />
                    </svg>
                </div>
                <div className="has-background-turquoise-green">
                    <div className="container mx-auto px-4">
                        <div className="columns mt-0 mb-0">
                            <div className="column ml-auto mr-auto px-4">
                                <div className="">
                                    <h3
                                        className="is-size-1 is-size-4-touch has-text-weight-semibold has-text-xinetic"
                                    >
                                        Annunci premium sui portali dedicati e social
                                    </h3>
                                    <p
                                        className="is-size-4 is-size-6-touch has-text-xinetic has-text-justified"
                                    >
                                        Avere foto e video professionali è importante tanto quanto
                                        avere un annuncio ben posizionato in rete. Per questo
                                        investiamo molto sulla pubblicità per dare massima visibilità
                                        agli immobili, sia sui maggiori portali dedicati, sia sui
                                        social, con sponsorizzazioni personalizzate.
                                    </p>
                                </div>
                            </div>
                            <div className="column is-two-fifths ml-auto mr-auto px-4">
                                <div
                                    className="p-3 has-text-centered inline-flex items-center justify-center mb-6"
                                >
                                    <i
                                        className="fas fa-rocket big-icon has-text-light-goldenrod-yellow has-background-white is-circular"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-auto mt-0 ml-0 mr-0 is-flex">
                    <svg
                        className="absolute m-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-turquoise-green fill-current"
                            points="0 0 2560 0 2560 250"
                        />
                    </svg>
                </div>
            </section>

            <section>
                <div className="mt-6 ml-0 mr-0 is-flex absolute">
                    <svg
                        className="absolute"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon className="fill-morning-blue" points="0 250 2560 250 2560 0" />
                    </svg>
                </div>
                <div className="has-background-morning-blue">
                    <div className="container">
                        <div className="columns mt-0 mb-0">
                            <div className="column is-two-fifths ml-auto mr-auto px-4">
                                <div
                                    className="p-3 has-text-centered inline-flex items-center justify-center mb-6"
                                >
                                    <i
                                        className="fas fa-magic big-icon has-text-light-goldenrod-yellow has-background-white is-circular"
                                    ></i>
                                </div>
                            </div>
                            <div className="column ml-auto mr-auto px-4">
                                <div className="">
                                    <h3
                                        className="is-size-1 is-size-4-touch has-text-weight-semibold has-text-xinetic"
                                    >
                                        Contratti on-line
                                    </h3>
                                    <p className="is-size-4 is-size-6-touch has-text-xinetic">
                                        Ora puoi firmare i documenti comodamente dal tuo smartphone
                                        con la nostra contrattualistica on-line che permette di
                                        autenticare in pochi passi la tua firma con piena valenza
                                        legale, anche se non disponi di una tua firma digitale,
                                        evitando così spreco di carta e spostamenti superflui. Utile
                                        per noi e per l’ambiente.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-auto mt-0 ml-0 mr-0 is-full absolute overflow-hidden">
                    <svg
                        className="absolute m-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-morning-blue fill-current"
                            points="0 0 0 250 2560 0"
                        />
                    </svg>
                </div>
            </section>

            <section>
                <div className="mt-6 ml-0 mr-0 is-flex absolute">
                    <svg
                        className="absolute mb-0"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-turquoise-green fill-current"
                            points="0 0 0 250 2560 250"
                        />
                    </svg>
                </div>
                <div className="has-background-turquoise-green">
                    <div className="container mx-auto px-4">
                        <div className="columns mt-0 mb-0">
                            <div className="column ml-auto mr-auto px-4">
                                <div className="">
                                    <h3
                                        className="is-size-1 is-size-4-touch has-text-weight-semibold has-text-xinetic"
                                    >
                                        Verifica della situazione giuridica/catastale
                                    </h3>
                                    <p className="is-size-4 is-size-6-touch has-text-xinetic">
                                        La maggior parte dei problemi che sorge durante le
                                        compravendite immobiliari è di natura giuridica e/o catastale
                                        urbanistica. Verifichiamo tutta la documentazione
                                        dell’immobile e laddove ci fossero difformità ci occupiamo noi
                                        di tutto per garantire una transazione serena e senza brutte
                                        sorprese al rogito.
                                    </p>
                                </div>
                            </div>
                            <div className="column is-two-fifths ml-auto mr-auto px-4">
                                <div
                                    className="has-text-light-goldenrod-yellow p-3 has-text-centered inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300"
                                >
                                    <i
                                        className="fas fa-user-md big-icon has-text-light-goldenrod-yellow has-background-white is-circular"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-auto mt-0 ml-0 mr-0 is-flex">
                    <svg
                        className="absolute m-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-turquoise-green fill-current"
                            points="0 0 2560 0 2560 250"
                        />
                    </svg>
                </div>
            </section>

            <section>
                <div className="mt-6 ml-0 mr-0 is-flex absolute">
                    <svg
                        className="absolute"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon className="fill-morning-blue" points="0 250 2560 250 2560 0" />
                    </svg>
                </div>
                <div className="has-background-morning-blue">
                    <div className="container">
                        <div className="columns mt-0 mb-0">
                            <div className="column is-two-fifths ml-auto mr-auto px-4">
                                <div
                                    className="p-3 has-text-centered inline-flex items-center justify-center mb-6"
                                >
                                    <i
                                        className="fa fa-city big-icon has-text-light-goldenrod-yellow has-background-white is-circular"
                                    ></i>
                                </div>
                            </div>
                            <div className="column ml-auto mr-auto px-4">
                                <div className="">
                                    <h3
                                        className="is-size-1 is-size-4-touch has-text-weight-semibold has-text-xinetic"
                                    >
                                        Gestione immobiliare
                                    </h3>
                                    <p className="is-size-4 is-size-6-touch has-text-xinetic">
                                        Se hai uno o più immobili da mettere a reddito ma non hai il
                                        tempo o la possibilità di occupartene abbiamo il servizio
                                        personalizzato che fa per te. Ci occupiamo dei rapporti con
                                        l’inquilino, siamo in contatto con ditte di traslochi,
                                        imbianchini, idraulici, elettricisti e imprese di pulizie. Per
                                        qualsiasi imprevisto ci siamo noi.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-auto mt-0 ml-0 mr-0 is-full absolute overflow-hidden">
                    <svg
                        className="absolute m-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-morning-blue fill-current"
                            points="0 0 0 250 2560 0"
                        />
                    </svg>
                </div>
            </section>

            <section>
                <div className="mt-6 ml-0 mr-0 is-flex absolute">
                    <svg
                        className="absolute mb-0"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-turquoise-green fill-current"
                            points="0 0 0 250 2560 250"
                        />
                    </svg>
                </div>
                <div className="has-background-turquoise-green">
                    <div className="container mx-auto px-4">
                        <div className="columns mt-0 mb-0">
                            <div className="column ml-auto mr-auto px-4">
                                <div className="">
                                    <h3
                                        className="is-size-1 is-size-4-touch has-text-weight-semibold has-text-xinetic"
                                    >
                                        Progettazione e ristrutturazione
                                    </h3>
                                    <p className="is-size-4 is-size-6-touch has-text-xinetic">
                                        Siamo in contatto con imprese edili, studi di architettura,
                                        geometri e professionisti del settore, per offrirti una figura
                                        professionale per qualsiasi esigenza legata al tuo immobile.
                                    </p>
                                </div>
                            </div>
                            <div className="column is-two-fifths ml-auto mr-auto px-4">
                                <div
                                    className="p-3 has-text-centered inline-flex items-center justify-center w-16 h-16 mb-6"
                                >
                                    <i
                                        className="fas fa-pencil-ruler big-icon has-text-light-goldenrod-yellow has-background-white is-circular"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-auto mt-0 ml-0 mr-0 is-flex">
                    <svg
                        className="absolute m-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 250"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-turquoise-green fill-current"
                            points="0 0 2560 0 2560 250"
                        />
                    </svg>
                </div>
            </section>

            {/* <!-- Contact Us Section --> */}
            <section className="hero" id="contact-us">
                <div className="hero-body">
                    <div className="container">
                        <div>
                            <div className="content">
                                <h4 className="title is-1">Contattaci</h4>
                            </div>
                            <h6 className="subtitle is-6">
                                Vorresti conoscere il valore della tua casa o di quella dei tuoi
                                sogni? Ti forniamo una valutazione affidabile, gratuita e senza
                                impegno.
                            </h6>
                            <div className="columns box">
                                <div className="column">
                                    <form
                                        className="column"
                                        action="mailto:info@hometrust.it"
                                        method="post"
                                        encType="text/plain"
                                    >
                                        <input
                                            className="input"
                                            type="hidden"
                                            name="subject"
                                            value="richiesta di info dal sito"
                                        />

                                        <div className="field">
                                            <label className="label">Email</label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    name="from"
                                                    placeholder="Email address"
                                                    required=""
                                                />
                                            </div>
                                        </div>

                                        <div className="field">
                                            <label className="label">Messaggio</label>
                                            <div className="control">
                                                <textarea
                                                    className="textarea"
                                                    type="text"
                                                    name="body"
                                                    placeholder="Type your message here..."
                                                    required=""
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label"></label>
                                            <div className="control">
                                                <input
                                                    className="button is-primary"
                                                    type="submit"
                                                    value="Invia"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="column">
                                    {/* <!-- <iframe className="align-middle"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.6093403150585!2d9.202912915868787!3d45.45752787910098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c69ff7ab2653%3A0x84d57960e78f19a6!2sViale%20Regina%20Margherita%2C%202%2C%2020122%20Milano%20MI!5e0!3m2!1sit!2sit!4v1643826857350!5m2!1sit!2sit"
                                        style="border: 0"
                                        allowfullscreen=""
                                        loading="lazy"
                                        width="100%"
                                        height="100%"
                                    ></iframe> --> */}
                                    <img src="/media/hometrustmap.png" alt="map" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ./Contact Us Section --> */}


            {/* <!-- Footer Section --> */}
            <footer className="hero">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">

                            <div className="column is-3" style={{ textAlign: "center" }}>
                                <div className="content">
                                    <div>
                                        <h6 className="footer_title title is-6">SOCIAL MEDIA</h6>
                                        <p>Seguici sui nostri social media</p>
                                        <a className="button is-medium is-facebook">
                                            <span className="icon">
                                                <i className="fab fa-facebook-f fa-lg"></i>
                                            </span>
                                        </a>
                                        <a className="button is-medium is-instagram">
                                            <span className="icon">
                                                <i className="fab fa-instagram fa-lg"></i>
                                            </span>
                                        </a>
                                        {/* <!--
                                        <a className="button is-medium is-foursquare">
                                            <span className="icon">
                                                <i className="fab fa-foursquare fa-lg"></i>
                                            </span>
                                        </a>
                                        <a className="button is-medium is-twitter">
                                            <span className="icon">
                                                <i className="fab fa-twitter fa-lg"></i>
                                            </span>
                                        </a>
                                        <a className="button is-medium is-linkedin">
                                            <span className="icon">
                                                <i className="fab fa-linkedin-in fa-lg"></i>
                                            </span>
                                        </a> --> */}
                                    </div>
                                </div>
                            </div>
                            <div className="column is-3">
                                <div className="content">
                                    <h6 className="footer_title title is-6">INDIRIZZO</h6>
                                    {/* <!-- <p className="is-marginless">Reach the destinaiton safely</p> --> */}
                                    <p className="is-marginless">
                                        Largo Francesco Richini 6 - Milano - 20122 - Italia
                                    </p>
                                    <div>
                                        <i className="fa fa-phone"></i> &nbsp;
                                        <span>Telefono</span> &nbsp;
                                        <a href="tel:+302109999999">+39 0245480327</a>
                                        <br />
                                        <i className="fa fa-phone"></i> &nbsp;
                                        <span>Telefono</span> &nbsp;
                                        <a href="tel:+302109999999">+39 3929308548</a>
                                        <br />
                                        <i className="fa fa-mail"></i> &nbsp; <span>E-Mail</span> &nbsp;
                                        <a href="mailto:info@hometrust.it">info@hometrust.it</a>
                                    </div>
                                </div>
                            </div>

                            <div className="column is-3">
                                <div className="content">
                                    {/* <!-- <h6 className="footer_title title is-6">P. IVA</h6> -->
                                    <!-- <p className="is-marginless">Reach the destinaiton safely</p> -->
                                    <!-- <p className="is-marginless">P.IVA</p> --> */}
                                    <div>
                                        <span>P.IVA</span> &nbsp; 09317650969
                                        <br />
                                        <span>Iscrizione REA</span> &nbsp; MI-2645706
                                    </div>
                                </div>
                            </div>

                            <div className="column is-3">
                                <div className="content">
                                    <h6 className="footer_title title is-6"><a href="/privacy">PRIVACY</a></h6>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="hero-foot">
                    <div className="container has-text-centered">
                        <p className="footer-text">
                            &copy; 2022 - Home Trust di Andrea Giovanni Dello Ioio. Tutti i
                            diritti riservati. <br />
                            <a href="" style={{ color: "white" }}
                            >Designed with
                                <i
                                    className="fa fa-heart fa-fw"
                                    style={{ fontSize: "10px; color: red" }}
                                ></i>
                                by Matteo Dello Ioio</a
                            >
                        </p>
                    </div>
                </div>
            </footer>


            <div className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Abbiamo a cuore la tua privacy</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        {/* <!-- Content ... --> */}
                        <p>Per maggiori informazioni accedi alla <a href="#privacy">Cookie Policy e all’Informativa Privacy.</a></p>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-success">Save changes</button>
                        <button className="button">Cancel</button>
                    </footer>
                </div>
            </div>
            <Outlet />
        </>
    );
}