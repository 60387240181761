

export const Privacy = () => {
    return (
        <section className="privacy">
            <div className="container">
                <h1 className="is-size-4 is-size-4-touch mb-2 has-text-weight-semibold">
                    Informativa sulla tutela dei dati personali e sull'utilizzo dei cookie
                    ai sensi del Regolamento (UE) 2016/679 ("GDPR")
                </h1>
                <p>Gentile Cliente,</p>
                <br />
                <p>
                    la società Home Trust Di Andrea Giovanni Dello Ioio, con sede legale
                    in Largo Francesco Richini N. 6 - 20122 Milano (MI) (di seguito, la
                    "Società"), è il Titolare del trattamento dei dati da Lei forniti al
                    momento della registrazione sul sito, o comunque acquisiti in sede di
                    prestazione dei servizi a cui Lei può accedere. E' possibile inviare
                    richieste o comunicazioni al Titolare all'indirizzo email
                    info@hometrust.it.
                </p>

                <br />

                <p>
                    L'accesso al sito non richiede l'inserimento delle proprie generalità.
                    Per l'eventuale fruizione del servizio on line 'contatti' e per avere
                    accesso ad altri servizi aggiuntivi, dovrà inserire i Suoi dati
                    personali in apposite sezioni del sito.
                </p>

                <br />

                <p>
                    I dati inseriti verranno trattati con misure di sicurezza adeguate
                    agli attuali standard tecnologici e rispettando gli obblighi previsti
                    dal Regolamento (UE) 2016/679 sulla protezione dei dati personali
                    ("GDPR"). Secondo la normativa indicata, tale trattamento sarà
                    improntato ai principi di correttezza, liceità e trasparenza e di
                    tutela della Sua riservatezza e dei Suoi diritti. La seguente
                    informativa è relativa solamente al presente sito e non riguarda altri
                    siti web eventualmente consultati dall'utente attraverso link di
                    reindirizzamento posti nelle pagine del nostro sito. Nessun dato
                    derivante dalla consultazione del servizio web viene comunicato o
                    diffuso. Ai sensi dell'articolo 13 del GDPR, pertanto, Le forniamo le
                    seguenti informazioni:
                </p>

                <div className="list">
                    <ol>
                        <div className="list-item">
                            <li>
                                La Società raccoglie e tratta i Suoi dati personali per finalità
                                necessarie o strumentali alla fornitura dei servizi da Lei
                                richiesti e prestati tramite il presente sito, anche mediante
                                comunicazione dei dati a società terze di cui all'art. 5 della
                                presente informativa (nominate dalla Società quali responsabili
                                del trattamento dei dati) ai fini della gestione tecnica e
                                amministrativa dei servizi. I vostri dati potranno essere
                                trattati per le finalità interne di compilazione di liste
                                anagrafiche, tenuta della contabilità, fatturazione, gestione
                                del creditore per la soddisfazione di tutti gli obblighi
                                previsti dalle normative vigenti, scopi statistici, per
                                comunicazioni, e servizi aggiuntivi da Lei esplicitamente
                                richiesti. Le basi giuridiche del trattamento, a seconda dei
                                casi, possono essere il Suo consenso, l'esecuzione di un
                                contratto di cui Lei è parte o l'adempimento di obblighi legali
                                cui è soggetta la Società in qualità di Titolare del trattamento
                            </li>
                        </div>

                        <div className="list-item">
                            <li>
                                Il trattamento dei Suoi dati potrà inoltre avvenire: (a) per
                                inviare informazioni ed offerte commerciali di servizi2. analoghi
                                a quelli da lei acquistati in occasione dell'accesso al Sito,
                                salvo che Lei si opponga a questo trattamento mediante invio di
                                una mail all'indirizzo email info@hometrust.it e, ove lei presti
                                il suo consenso espresso, (b) per inviare informazioni ed offerte
                                commerciali, materiale pubblicitario e informativo, effettuare
                                comunicazioni commerciali, anche interattive, compiere attività
                                dirette di vendita o di collocamento di prodotti o servizi, anche
                                di terzi.
                            </li>
                        </div>

                        <div className="list-item">
                            <li>
                                Il trattamento sarà effettuato sia manualmente che utilizzando strumenti elettronici, nell'osservanza di tutte le3.
                                cautele necessarie a garantire la sicurezza e la riservatezza delle informazion
                            </li>
                        </div>


                        <div className="list-item">
                            <li>
                                I Suoi dati potranno essere trattati all'interno della nostra Società da tutti gli incaricati al trattamento nominati per4.
                                iscritto dal Titolare del trattamento e formati sugli obblighi della Legge in materia di Privacy.
                            </li>
                        </div>

                        <div className="list-item">
                            <li>
                                I dati potranno essere comunicati a terze parti, esclusivamente per esigenze tecniche ed operative strettamente5.
                                collegate alle finalità sopraelencate ed in particolare alle seguenti categorie di soggetti: a) Enti, professionisti, società
                                o altre strutture da noi incaricate dei trattamenti connessi all'adempimento degli obblighi amministrativi, contabili,
                                commerciali e gestionali legati all'ordinario svolgimento della nostra attività economica, anche per finalità di
                                recupero credito; b) Alle pubbliche autorità ed amministrazioni per le finalità connesse all'adempimento di obblighi
                                legali; c) Banche, istituti finanziari o altri soggetti per cui il trasferimento dei dati risulti necessario ai fini dello
                                svolgimento delle attività della nostra Società, in particolare in relazione all'assolvimento, da parte nostra, delle
                                obbligazioni contrattuali assunte nei Suoi confronti.
                            </li>
                        </div>


                        <div className="list-item">
                            <li>
                                I suoi dati personali saranno conservati nei server a disposizione della Società collocati nell'Unione europea. La6.
                                Società non effettua trasferimenti di dati personali verso Paesi al di fuori dell'Unione Europea.
                            </li>
                        </div>


                        <div className="list-item">
                            <li>
                                I dati personali che la riguardano saranno conservati per tutto il tempo di durata del Suo rapporto contrattuale con la7.
                                Società. Successivamente alla cessazione del rapporto contrattuale, la Società conserverà i dati personali relativi
                                all'esecuzione del contratto per l'adempimento degli obblighi di contratto e di legge, anche di tipo fiscale.
                                Successivamente, i dati personali relativi all'esecuzione del contratto verranno conservati per un periodo non
                                eccedente il termine di prescrizione previsto dalla legge per far eventualmente valere o difendere un diritto in
                                giudizio.
                            </li>
                        </div>

                        <div className="list-item">
                            <li>
                                Il conferimento dei Suoi dati personali è facoltativo, fermo restando che il rifiuto di divulgare i dati o di prestare il8.
                                consenso al trattamento di cui al punto 1 della presente informativa comporterà l'impossibilità, da parte nostra, di
                                concludere il contratto ed erogare gli eventuali servizi richiesti e di adempiere agli obblighi di legge. In caso di rifiuto
                                del trattamento dei dati personali di cui all'art. 2 della presente informativa, il trattamento sarà limitato all'integrale
                                esecuzione degli obblighi derivanti dalla fornitura dei servizi da Lei richiesti, nonché all'adempimento degli obblighi
                                previsti da leggi, regolamenti e normativa comunitaria.
                            </li>
                        </div>

                        <div className="list-item">
                            <li>
                                Il Titolare del trattamento è Home Trust Di Andrea Giovanni Dello Ioio, con sede legale in Largo Francesco Richini N. 69.
                                - 20122 Milano (MI). L'elenco degli eventuali responsabili del trattamento dei dati personali è disponibile presso gli
                                uffici del Titolare e può essere richiesto mediante l'invio di una mail all'indirizzo info@hometrust.it.
                            </li>
                        </div>

                        <div className="list-item">
                            <li>
                                <div>
                                    <p>
                                        In ogni momento potrà esercitare i Suoi diritti nei confronti del Titolare del trattamento, ai sensi degli artt. 15-2210.
                                        GDPR, che per Sua comodità riassumiamo di seguito. In particolare, Lei ha il diritto:
                                    </p>
                                    <ul className="circle">
                                        <li>
                                            di ottenere la cessazione del trattamento nei casi in cui i suoi dati personali siano trattati per fini di marketing
                                            diretto, anche in relazione a servizi identici a quelli già acquistati dalla nostra Società (c.d. diritto di
                                            opposizione);
                                        </li>

                                        <li>

                                            di ottenere informazioni in relazione alle finalità per cui i Suoi dati personali sono trattati, al periodo del
                                            trattamento e ai soggetti cui i dati sono comunicati (c.d. diritto di accesso);
                                        </li>

                                        <li>
                                            di ottenere la rettifica o integrazione dei dati personali inesatti che La riguardano (c.d. diritto di rettifica);
                                        </li>

                                        <li>
                                            di ottenere la cancellazione dei dati personali che La riguardano nei seguenti casi (a) i dati non siano più
                                            necessari per le finalità per le quali sono stati raccolti; (b) Lei abbia ritirato il Suo consenso al trattamento dei
                                            dati qualora essi siano trattati sulla base del Suo consenso; (c) Lei si sia opposto al trattamento dei dati
                                            personali che La riguardano nel caso in cui essi siano trattari per un nostro legittimo interesse; o (d) il
                                            trattamento dei suoi dati personali non sia conforme alla legge. Tuttavia, Le segnaliamo che la conservazione
                                            dei dati personali da parte della Società è lecita qualora sia necessaria per consentirle di adempiere un
                                            obbligo legale o per accertare, esercitare o difendere un diritto in sede giudiziaria (c.d. diritto di
                                            cancellazione);
                                        </li>

                                        <li>

                                            di ottenere che i dati personali che La riguardano siano solo conservati senza che di essi sia fatto altro uso nei
                                            seguenti casi (a) Lei contesti l'esattezza dei dati personali, per il periodo necessario a consentirci di verificare
                                            l'esattezza di tali dati personali; (b) il trattamento sia illecito ma Lei si opponga comunque alla cancellazione
                                            dei dati personali da parte nostra; (c) i dati personali Le siano necessari per l'accertamento, l'esercizio o la
                                            difesa di un diritto in sede giudiziaria; (d) Lei si sia opposto al trattamento e si sia in attesa della verifica in
                                            merito all'eventuale prevalenza dei nostri motivi legittimi al trattamento rispetto a quelli dell'interessato (c.d.
                                            diritto di limitazione);
                                        </li>

                                        <li>
                                            di ricevere in un formato di uso comune, leggibile da dispositivo automatico e interoperabile i dati personali
                                            che La riguardano trattati con mezzi automatizzati, se essi siano trattati in forza di contratto o sulla base del
                                            Suo consenso (c.d. diritto di portabilità).
                                        </li>
                                    </ul>
                                    <p>
                                        Le ricordiamo inoltre che Lei ha diritto di rivolgersi al Garante per la protezione dei dati personali (Piazza Venezia, 11
                                        - 00187 Roma) per far valere i Suoi diritti in relazione al trattamento dei Suoi dati personali.
                                    </p>
                                </div>

                            </li>
                        </div>
                    </ol>
                </div>

                <h3 className="is-size-4">Uso dei cookie</h3>

                <p>
                    I cookie sono stringhe di testo di piccole dimensioni che i siti visitati dall'utente inviano al suo terminale (solitamente al
                    browser), dove vengono memorizzati per essere poi ritrasmessi agli stessi siti alla successiva visita del medesimo utente.
                    Nel corso della navigazione su un sito, l'utente può ricevere sul suo terminale anche cookie che vengono inviati da siti o da
                    web server diversi (c.d. "terze parti"), sui quali possono risiedere alcuni elementi (quali, ad esempio, immagini, mappe,
                    suoni, specifici link a pagine di altri domini) presenti sul sito che lo stesso sta visitando. Il sito web www.hometrust.it (di
                    seguito, il "Sito") utilizza i cookie per rendere i propri servizi semplici e efficienti per l'utenza che visiona le pagine del Sito.
                    Gli utenti che visionano il Sito, vedranno inserite delle quantità minime di informazioni nei dispositivi in uso, che siano
                    computer e periferiche mobili, in piccoli file di testo denominati "cookie" salvati nelle directory utilizzate dal browser web
                    dell'Utente. Vi sono vari tipi di cookie, alcuni per rendere più efficace l'uso del Sito, altri per abilitare determinate
                    funzionalità. Analizzandoli in maniera particolareggiata i nostri cookie permettono di:
                </p>
                <ul className="disc">
                    <li>
                        memorizzare le preferenze inserite dal visitatore;
                    </li>
                    <li>
                        evitare di reinserire le stesse informazioni più volte durante la visita, quali ad esempio nome utente e password;
                    </li>
                </ul>
                <p>
                    l trattamento è effettuato dal Titolare con strumenti automatizzati. Fatta eccezione per i cookie tecnici, strettamente
                    necessari alla normale navigazione, il conferimento dei dati è rimesso alla volontà del visitatore che decida di navigare sul
                    Sito dopo avere preso visione dell'informativa in forma breve (c.d. banner) e di usufruire dei servizi che comportano
                    l'installazione dei cookie. L'utente può quindi evitare l'installazione dei cookie, eccezion fatta per i cookie tecnici,
                    astenendosi dall'intraprendere alcuna azione in corrispondenza del banner oppure attraverso le apposite funzioni disponibili
                    sui diversi browser, descritte nel dettaglio nella presente informativa.
                </p>




                <h3 className="is-size-4">Tipologie di Cookie utilizzati dal Sito</h3>

                <h4 className="is-size-5">Cookie Tecnici (obbligatori)</h4>

                <p>
                    Questa tipologia di cookie permette il corretto funzionamento di alcune sezioni del Sito. Sono di due categorie:
                    persistenti e di sessione:
                </p>

                <ul className="disc">
                    <li>
                        <strong>persistenti</strong>: una volta chiuso il browser non vengono distrutti ma rimangono fino ad una data di scadenza
                        preimpostata;
                    </li>
                    <li>
                        <strong>di sessioni</strong>: vengono distrutti ogni volta che il browser viene chiuso
                    </li>
                </ul>
                <p>
                    Questi cookie, inviati sempre dal nostro dominio, sono necessari a visualizzare correttamente il sito e in relazione ai
                    servizi tecnici offerti, verranno quindi sempre utilizzati e inviati, a meno che l'utenza non modifichi le impostazioni nel
                    proprio browser (inficiando così alcune funzionalità o la visualizzazione delle pagine del sito).
                </p>

                <h4 className="is-size-5">Cookie Analitici</h4>

                <p>
                    I cookie in questa categoria vengono utilizzati per collezionare informazioni sull'uso del sito. Il Sito userà queste
                    informazioni in merito ad analisi statistiche anonime al fine di migliorare l'utilizzo del Sito e per rendere i contenuti più
                    interessanti e attinenti ai desideri dell'utenza. Questa tipologia di cookie raccoglie dati in forma anonima sull'attività
                    dell'utenza e su come è arrivata sul Sito. I cookie analitici sono inviati dal Sito Stesso o da domini di terze parti.
                    Il Sito utilizza Google Analytics, un servizio di analisi web fornito da Google, Inc. ("Google") . Le informazioni generate dai
                    cookie sull'utilizzo del sito web (compreso il Vostro indirizzo IP in forma anonima) verranno trasmesse e depositate presso i
                    server di Google. Google utilizzerà queste informazioni allo scopo di esaminare il Vostro utilizzo del sito web, compilare
                    report sulle attività del sito per gli operatori dello stesso e fornire altri servizi relativi alle attività del sito web e all'utilizzo di
                    internet. Google può anche trasferire queste informazioni a terzi ove ciò sia imposto dalla legge o laddove tali terzi trattino
                    le suddette informazioni per conto di Google. Google non assocerà il vostro indirizzo IP a nessun altro dato posseduto da
                    Google. Potete rifiutarvi di usare i cookies selezionando l'impostazione appropriata sul vostro browser, ma si noti che ciò
                    potrebbe compromettere la possibilità di fruire di determinate funzionalità di questo sito web. Utilizzando il presente sito
                    web, Lei acconsente al trattamento dei Suoi dati da parte di Google per le modalità ed i fini sopraindicati. E' possibile
                    impedire a Google il rilevamento di un cookie che viene generato dal Suo utilizzo di questo sito web (compreso il Suo
                    indirizzo IP) e l'elaborazione di tali dati, scaricando e installando il plugin per browser disponibile al seguente indirizzo web:
                    <a href="http://tools.google.com/dlpage/gaoptout?hl=en">http://tools.google.com/dlpage/gaoptout?hl=en</a>
                </p>


                <h4 className="is-size-5">Cookie di analisi di servizi di terze parti</h4>
                <p>Questi cookie sono utilizzati al fine di raccogliere informazioni sull'uso del Sito da parte degli utenti in forma anonima
                    quali: pagine visitate, tempo di permanenza, origini del traffico di provenienza, provenienza geografica, età, genere e
                    interessi ai fini di campagne di marketing. Questi cookie sono inviati da domini di terze parti esterni al Sito.

                    Questa tipologia di cookie integra funzionalità sviluppate da terzi all'interno delle pagine del Sito come le icone e le
                    preferenze espresse nei social network al fine di condivisione dei contenuti del sito o per l'uso di servizi software di terze
                    parti (come i software per generare le mappe e ulteriori software che offrono servizi aggiuntivi). Questi cookie sono inviati da
                    domini di terze parti e da siti partner che offrono le loro funzionalità tra le pagine del Sito
                </p>

                <h4 className="is-size-5">Marcatori di pixel</h4>

                <p>
                    Questo sito web potrebbe utilizzare i “marcatori di pixel”, ossia dei piccoli file grafici che consentono di monitorare l’utilizzo
                    del sito web. Un marcatore di pixel è in grado di raccogliere informazioni come l’indirizzo IP (protocollo Internet) del
                    computer che ha scaricato la pagina in cui appare il marcatore; l’URL (Uniform Resource Locator) della pagina in cui appare
                    il marcatore di pixel; l’ora in cui è stata visualizzata la pagina contenente il marcatore di pixel; il tipo di browser che ha
                    prelevato il marcatore di pixel e il numero di identificazione di qualsiasi cookie presente nel computer e precedentemente
                    inserito da quel server. In caso di scambio di corrispondenza con gli utenti tramite messaggi di posta elettronica che
                    supportano il formato HTML, è possibile che sia utilizzata la tecnologia di “rilevamento del formato”, che consente ai
                    marcatori di pixel di comunicare se l’interlocutore ha ricevuto e aperto il messaggio.
                </p>


                <h4 className="is-size-5">Cookie di profilazione</h4>

                <p>
                    Sono quei cookie necessari a creare profili utenti al fine di inviare messaggi pubblicitari in linea con le preferenze
                    manifestate dall'utente all'interno delle pagine del Sito.
                    Il Sito, secondo la normativa vigente, non è tenuto a chiedere consenso per i cookie tecnici e di analytics che non
                    permettono di identificare l'utente, in quanto necessari a fornire i servizi richiesti. Per tutte le altre tipologie di cookie, il
                    consenso può essere espresso dall'Utente con una o più di una delle seguenti modalità:
                </p>

                <ul className="disc">
                    <li>
                        Mediante l'accettazione e la revoca espressa del consenso all'uso dei cookie all'interno del Sito. Clicca
                        qui: INSERIRE LINK A PAGINA DI REVOCA
                    </li>
                    <li>
                        Mediante specifiche configurazioni del browser utilizzato o dei relativi programmi informatici utilizzati per
                        navigare le pagine che compongono il Sito. Clicca qui: INSERIRE LINK A PAGINA DI REVOCA
                    </li>
                    <li>
                        Mediante modifica delle impostazioni nell'uso dei servizi di terze parti. Clicca qui: INSERIRE LINK A PAGINA DI REVOCA
                    </li>
                </ul>
                <p>Le predette soluzioni potrebbero impedire all'utente di utilizzare determinate funzionalità o visualizzare parti del Sito.</p>

                <h4 className="is-size-5">Siti Web e servizi di terze parti</h4>

                <p>
                    Il Sito potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria informativa sulla privacy che può
                    essere diverse da quella adottata dal Sito e che quindi non risponde di questi siti. Ad esempio, il Sito riporta collegamenti
                    alle pagine social di Home Trust Di Andrea Giovanni Dello Ioio su Facebook, Instagram, Twitter, Google+, LinkedIn, Pinterest
                    e Youtube. Si precisa che cliccare sui link a tali social network potrebbe comportare l'installazione di cookies da parte di tali
                    terze parti. Per maggiori informazioni, si rimanda alle rispettive informative sull'utilizzo dei cookies.
                </p>

                <h4 className="is-size-5">Cookie utilizzati dal Sito</h4>

                <p>
                    A seguire la lista di tutti i cookie utilizzati da questo Sito:
                    non presenti cookie
                </p>

                <h4 className="is-size-5">Per disabilitare i cookie mediante revoca del consenso sul Sito clicca qui: INSERIRE LINK A PAGINA DI REVOCA</h4>

                <p>
                    Questa pagina è visibile, mediante link in calce in tutte le pagine del Sito ai sensi dell'art. 13 GDPR e in ossequio alle
                    modalità semplificate per l'informativa e l'acquisizione del consenso per l'uso dei cookie pubblicata sulla Gazzetta Ufficiale
                    n.126 del 3 giugno 2014 e relativo registro dei provvedimenti n.229 dell'8 maggio 2014.
                </p>
            </div>
        </section>
    )
}